import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { SingleColumnBody, Paragraph } from '@bestfit-team/bf-base-ui';

const CorporationsProblem = () => {
  const copy = useStaticQuery(graphql`
    query CorporationsProblemQuery {
      airtable(
        data: {Segment: {eq: "Corporations_Problem"}}, 
        ) {
          data {
            header
            status
            subheader_2
          }
      }
    }
  `);

console.log(JSON.stringify(copy))
  return (
    <>
      <SingleColumnBody 
        headingCopy={copy.airtable.data.header}
        subheadingCopy={copy.airtable.data.subheader}
      >
        <Paragraph> {copy.airtable.data.subheader_2} </Paragraph>
      </SingleColumnBody>
    </>
  )
}

export default CorporationsProblem;
