import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { PageGrid, SingleColumnBody, Paragraph, TwoColumnLayout } from '@bestfit-team/bf-base-ui';

const CorporationsWhyItMatters = ({ ...props }) => {
  const copy = useStaticQuery(graphql`
    query CorporationsWhyItMattersQuery {
      pageText: airtable(data: {Segment: {eq: "Why It Matters"}}) {
        data {
          header
          subheader_2
        }
      }
      pageImages: allAirtableAllImagesTable(filter: {data: {Content: {eq: "reclSyRaA5zxwW2B6"}}}) {
        nodes {
          data {
            file_name
            redirect_url
            attachments {
              filename
              url
            }
            alt_text
          }
        }
      }
    }
  `);

console.log(JSON.stringify(copy))
  return (
    <>
      <PageGrid {...props}>
      <SingleColumnBody
        headingCopy={copy.pageText.data.header}
        {...props}
      >
        <TwoColumnLayout
          variant={'EightFourRatio'}
        > 
          <TwoColumnLayout.Column         
            columnNumber={'colOne'}
            style={{
            }}
            {...props}
          >
            <Paragraph> {copy.pageText.data.subheader_2} </Paragraph>
          </TwoColumnLayout.Column> 

          <TwoColumnLayout.Column         
            columnNumber={'colTwo'}
            style={{
            }}
            {...props}
          >
            <img 
              src={copy.pageImages.nodes[0].data.attachments[0].url} 
              alt={copy.pageImages.nodes[0].data.alt_text} 
              style={{
              }}
            />
          </TwoColumnLayout.Column> 
        </TwoColumnLayout>
      </SingleColumnBody>
      </PageGrid>
    </>
  )
}

export default CorporationsWhyItMatters;
