import React from "react";
import { graphql, useStaticQuery } from 'gatsby';
import { Heading, HorizontalDivider } from '@bestfit-team/bf-base-ui';
import Layout from "../components/layout";
import CorporationsHero from "../sections/corporations/CorporationsHero";
import CorporationsProblem from "../sections/corporations/CorporationsProblem";
import CorporationsWhatWeDo from "../sections/corporations/CorporationsWhatWeDo";
import CorporationsWhyItMatters from "../sections/corporations/CorporationsWhyItMatters";
import CorporationsImpact from "../sections/corporations/CorporationsImpact";
import CorporationsPartnerWithUs from "../sections/corporations/CorporationsPartnerWithUs";
import CorporationsContactUs from "../sections/corporations/CorporationsContactUs";
import CorporationsPartners from "../sections/corporations/CorporationsPartners";
// import HomeHero from "../sections/home/HomeHero";

// TODO: #9 ForCorporations - stats
// TODO: #11 ForCorporations - good company
// TODO: #12 ForCorporations - partner now
// TODO: #46 ForCorporations - top-margin for header component [no whitespace above pic]
// TODO: #52 ForCorporations - what we do: 3-columns not working
// TODO: #53 ForCorporations - why it matters: 2-columns not working
// TODO: #54 ForCorporations - partner: 3-columns not working

const ForCorporations = ({ data, ...props }) => {
  console.log(data)
  return (
    <Layout
      pageTitle='For Corporations'
      {...props} 
    >
      <CorporationsHero />

      <CorporationsProblem />
      <HorizontalDivider />
      <CorporationsWhyItMatters />

      <CorporationsWhatWeDo />
      <CorporationsImpact />

      <Heading h2> stats </Heading>
      <CorporationsPartnerWithUs />
      <CorporationsPartners />
      <CorporationsContactUs />
      <HorizontalDivider />
      <Heading h2> footer </Heading>

    </Layout>
  )
}

export const query = graphql`
  query CorporationsHeroQuery {
    corporationsPageQuery: allAirtable(filter: {data: {page_title: {eq: "For Corporations"}}}) {
      nodes {
        data {
          Segment
          action
          action_url
          attached_images {
            url
            filename
          }
          header
          status
          subheader
          subheader_2
        }
      }
    }
  }
`

export default ForCorporations;