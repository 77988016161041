import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { WhatWeDo } from '@bestfit-team/bf-base-ui';

const CorporationsWhatWeDo = ({ ...props }) => {
  const copy = useStaticQuery(graphql`
    query CorporationsWhatWeDoQuery {
      pageText: airtable(data: {Segment: {eq: "What We Do"}}) {
        data {
          header
          subheader_2
        }
      }
      pageImages: allAirtableAllImagesTable(filter: {data: {Content: {eq: "recOOj0W8wmwcPcmK"}}}
        sort: {fields: data___section_order}
      ) {
        nodes {
          data {
            file_name
            redirect_url
            attachments {
              filename
              url
            }
            alt_text
          }
        }
      }
    }
  `);

console.log(JSON.stringify(copy))
  return (
    <>
      <WhatWeDo
        headingCopy={copy.pageText.data.header}
        bodyCopy={copy.pageText.data.subheader_2}
        // containerBackgroundColor='rgb(244, 244, 244)'
        spansFullPage
        variant={'FourFourFourRatio'}
        whatWeDoPictureOne={copy.pageImages.nodes[0].data.attachments[0].url}
        whatWeDoPictureTwo={copy.pageImages.nodes[1].data.attachments[0].url}
        whatWeDoPictureThree={copy.pageImages.nodes[2].data.attachments[0].url}
        whatWeDoAltPictureOne={copy.pageImages.nodes[0].data.alt_text}
        whatWeDoAltPictureTwo={copy.pageImages.nodes[1].data.alt_text}
        whatWeDoAltPictureThree={copy.pageImages.nodes[2].data.alt_text}
        {...props}
      />
    </>
  )
}

export default CorporationsWhatWeDo;
