import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { PartnerWithUs } from '@bestfit-team/bf-base-ui';

const CorporationsPartnerWithUs = ({ ...props }) => {
  const copy = useStaticQuery(graphql`
    query CorporationsPartnerWithUsQuery {
      corporationsPartnerText: allAirtable(
        filter: {data: {Segment: {glob: "PartnerWithUs*"}}}
        sort: {fields: data___Segment}
      ) {
        nodes {
          data {
            header
            subheader
            subheader_2
          }
        }
      }
    }
  `);

console.log(JSON.stringify(copy))
  return (
    <>
      <PartnerWithUs
        partnerWithUsContent={copy.corporationsPartnerText}
        {...props}
      />
    </>
  )
}

export default CorporationsPartnerWithUs;
