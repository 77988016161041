import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { SingleColumnImpact } from '@bestfit-team/bf-base-ui';

const CorporationsImpact = ({ ...props }) => {
  const copy = useStaticQuery(graphql`
    query CorporationsImpactQuery {
      corporationsImpactContent: airtable(data: {Segment: {eq: "Corporations_Impact"}}) {
      data {
        header
        attached_images {
          url
        }
      }
    }
  }
`);

console.log(JSON.stringify(copy))
  return (
    <>
      <SingleColumnImpact
        impactCopy={copy.corporationsImpactContent.data.header}
      />
    </>
  )
}

export default CorporationsImpact;
