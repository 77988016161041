import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { Hero } from '@bestfit-team/bf-base-ui';

const CorporationsHero = () => {
  const copy = useStaticQuery(graphql`
    query CorporationsHeroQuery2 {
      CorporationsHeroInfo: airtable(
        data: {Segment: {eq: "Corporations_Header"}}, 
        ) {
          data {
            header
            status
            subheader
            action
            Segment
            action_url
            attached_images {
              filename
              url
            }
          }
      }
    }
  `);

  return (
    <Hero 
      heroBackgroundColor='var(--bf-op45-core-cream)'
      backgroundImageUrl={'https://best-fit.app/static/triangle-grey-25777a8336e851acd1255a9ef785a027.svg'}

      headingCopy={copy.CorporationsHeroInfo.data.header}
      subheadingCopy={copy.CorporationsHeroInfo.data.subheader}
      buttonLabel={copy.CorporationsHeroInfo.data.action}
      buttonUrl={copy.CorporationsHeroInfo.data.action_url}
      heroImageUrl={copy.CorporationsHeroInfo.data.attached_images[0].url}
      heroImageAlt={copy.CorporationsHeroInfo.data.attached_images[0].filename}
    />
  )
}

export default CorporationsHero;
